import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import './MembershipTypes.scss';
import { useNavigate } from 'react-router-dom';

function PriceWrapper(props) {
  const { children } = props;

  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}
    >
      {children}
    </Box>
  );
}

export default function MembershipTypes() {
  const navigate = useNavigate();
  return (
    <div id="membership-types">
      <Box py={12} mt={12} mb={12}>
        <VStack spacing={2} textAlign="center">
          <Heading
            as="h1"
            fontWeight={800}
            color={'gray.800'}
            fontSize={{ base: '4xl', sm: '5xl', md: '7xl' }}
          >
            Become a Biome Squad™ member
          </Heading>
          {/* <Text fontSize="lg" color={'gray.500'}>
          Start with 14-day free trial. No credit card needed. Cancel at
          anytime.
        </Text> */}
        </VStack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          textAlign="center"
          justify="center"
          spacing={{ base: 4, lg: 10 }}
          py={10}
        >
          <PriceWrapper>
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                General Member
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="5xl" fontWeight="900">
                  Free
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius={'xl'}
            >
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Access to community of like-minded pioneers
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Chat with the biosquad community
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Learn about completed microbiome studies and findings
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Learn about new microbiome standards, diagnostics, therapies,
                  treatments, technologies, and more
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button
                  w="full"
                  colorScheme="blue"
                  onClick={() => navigate('/sign-up')}
                >
                  Sign Up
                </Button>
              </Box>
            </VStack>
          </PriceWrapper>

          <PriceWrapper>
            <Box position="relative">
              <Box py={4} px={12}>
                <Text fontWeight="500" fontSize="2xl">
                  Certified Members
                </Text>
                <HStack justifyContent="center">
                  <Text fontSize="5xl" fontWeight="900">
                    Free
                  </Text>
                </HStack>
              </Box>
              <VStack
                bg={useColorModeValue('gray.50', 'gray.700')}
                py={4}
                borderBottomRadius={'xl'}
              >
                <List spacing={3} textAlign="start" px={12}>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Certified is only for Physicians and Scientists
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Everything a General Member has access to
                  </ListItem>

                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Get a chance to collaborate on studies investigating the gut
                    flora in disease
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Ability to order genetic sequencing services to your
                    patients
                  </ListItem>
                </List>
                <Box w="80%" pt={7}>
                  <Button
                    w="full"
                    colorScheme="blue"
                    onClick={() => navigate('/sign-up?type=certified')}
                  >
                    Sign Up
                  </Button>
                </Box>
              </VStack>
            </Box>
          </PriceWrapper>
          <PriceWrapper>
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Premium Certified Member
              </Text>
              <HStack justifyContent="center">
                {/* <Text fontSize="3xl" fontWeight="600">
                  $
                </Text> */}
                <Text fontSize="5xl" fontWeight="900">
                  Coming Soon
                </Text>
                {/* <Text fontSize="3xl" color="gray.500">
                  /month
                </Text> */}
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius={'xl'}
            >
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Certified is only for Physicians and Scientists
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Everything that a Certified Member has access to
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Enhanced dashboard to view patient sequencing results
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Extensive access to the Micrombiome Encylopedia™ providing
                  information on thousands of discovered microbiome bacteria,
                  definitions and health implications
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                {/* <Button w="full" colorScheme="blue" variant="outline">
                  Join the Waitlist
                </Button> */}
              </Box>
            </VStack>
          </PriceWrapper>
        </Stack>
      </Box>
    </div>
  );
}
