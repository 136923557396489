export const certifiedUserTypes = [
  {
    id: 0,
    value: 'Physician/Health Practitioner',
  },
  {
    id: 1,
    value: 'Nurse Practitioner',
  },
  {
    id: 2,
    value: 'PHD/Researcher',
  },
];

const NAV_ITEMS_MD = [
  /* {
    label: 'Library',
    link: '/libary',
  }, */
  {
    label: 'Patients',
    link: '/patients',
  },
  {
    label: 'Labs',
    link: '/labs',
  },
  {
    label: 'Meetings + Videos',
    link: '/meetings',
  },
  {
    label: 'Certified Members',
    link: '/certified-members',
  },
  {
    label: 'Biome Chat',
    link: '/biome-chat',
  },
];

const NAV_ITEMS_NON_MD = [
  /* {
    label: 'Library',
    link: '/libary',
  }, */
  {
    label: 'Meetings + Videos',
    link: '/meetings',
  },
  {
    label: 'Certified Members',
    link: '/certified-members',
  },
  {
    label: 'Biome Chat',
    link: '/biome-chat',
  },
];

const NAV_ITEMS_ADMIN = [
  /* {
    label: 'Library',
    link: '/libary',
  }, */
  {
    label: 'Patients',
    link: '/patients',
  },
  {
    label: 'Labs',
    link: '/labs',
  },
  {
    label: 'Meetings + Videos',
    link: '/meetings',
  },
  {
    label: 'Biome Chat',
    link: '/biome-chat',
  },
  {
    label: 'Certified Members',
    link: '/certified-members',
  },
  {
    label: 'Admin',
    link: '/admin/users',
  },
];

export const getNavItems = currentUser => {
  if (currentUser?.type === 'admin') return NAV_ITEMS_ADMIN;
  if (
    currentUser?.member ===
    certifiedUserTypes[certifiedUserTypes.length - 1].value
  ) {
    return NAV_ITEMS_NON_MD;
  } else {
    return NAV_ITEMS_MD;
  }
};

export const getAge = dateString => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
